
import { defineComponent, PropType, computed } from "vue";
import { DiceTable } from "@/dices";

export default defineComponent({
  props: { diceTable: { type: Object as PropType<DiceTable>, required: true } },
  setup(props) {
    const dicesDisplay = computed(() => {
      const dices: { prefix: string; diceType: string; suffix: string }[] = [];

      for (const [diceType, diceData] of Object.entries(props.diceTable)) {
        if (diceData.number) {
          const prefix = diceData.number > 1 ? `${diceData.number}x` : "";
          dices.push({ prefix: prefix, diceType: diceType, suffix: "" });
        }
      }

      for (const [index, dice] of dices.entries()) {
        dice.suffix = index !== dices.length - 1 ? " + " : " roll is ";
      }

      return dices;
    });

    const rollSum = computed(() => {
      let sum = 0;
      for (const diceData of Object.values(props.diceTable)) {
        sum += diceData.roll ? diceData.roll : 0;
      }
      return sum;
    });

    return { rollSum, dicesDisplay };
  }
});
