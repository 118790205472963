
import { defineComponent } from "vue";
import axios from "axios";
import randomName from "@/randomName";

export default defineComponent({
  name: "Home",
  props: {
    initialUsername: {
      type: String
    },
    initialSessionId: {
      type: String
    }
  },
  data() {
    return {
      username: "",
      sessionId: "",
      lastSessionId: "",
      errors: [] as string[],
      submitting: false,
      invalidSession: null as boolean | null
    };
  },
  created() {
    if (this.initialUsername) {
      this.username = this.initialUsername;
    }
    if (this.initialSessionId) {
      this.sessionId = this.initialSessionId;
    }
    this.getLastSessionId();
  },
  methods: {
    getLastSessionId() {
      const storedSession = localStorage.getItem("lastSessionId");
      if (storedSession) {
        this.lastSessionId = storedSession;
      }
    },
    defaultUsername() {
      return this.username ? this.username : randomName();
    },
    checkSessionId() {
      const re = /^[a-zA-Z0-9]{6}$/;
      this.invalidSession = !re.test(this.sessionId);
    },
    joinLastSession() {
      this.$router.push({
        name: "session",
        params: { sessionId: this.lastSessionId, initialUsername: this.username }
      });
    },
    joinSession() {
      this.checkSessionId();

      if (this.invalidSession) {
        return;
      }

      this.$router.push({
        name: "session",
        params: { sessionId: this.sessionId, initialUsername: this.username }
      });
    },
    async createSession() {
      this.submitting = true;
      try {
        const newSession = await axios.get(process.env.VUE_APP_FUNCTIONS_URL + "/createSession");
        if (newSession.data.state === -1 || newSession.data.state === -2) {
          this.errors.push(newSession.data.msg);
        } else {
          this.sessionId = newSession.data.data;
        }
      } catch (err) {
        this.errors.push("Server Error.");
      }

      if (!this.sessionId) {
        this.submitting = false;
        return;
      }

      this.$router.push({
        name: "session",
        params: { sessionId: this.sessionId, initialUsername: this.defaultUsername() }
      });
    }
  },
  computed: {
    getDomain() {
      return window.location.origin;
    },
    lastSessionDisabled(): boolean {
      return !this.lastSessionId;
    }
  }
});
