
import { defineComponent } from "vue";
import fb from "firebase/app";
import rs from "randomstring";
import { DisplayRoll, createNewDiceTable, reRollDices, dicesToStr, strToDices, validateDices } from "@/dices";
import ViewRoll from "@/components/ViewRoll.vue";
import randomName from "@/randomName";

const ROLLS_TO_DISPLAY = 250;

type SessionStatus = "exists" | "doesNotExist" | "undetermined";

const timestamp = function() {
  return Math.floor(Date.now() / 1000);
};

export default defineComponent({
  name: "Session",
  components: { ViewRoll },
  props: {
    initialUsername: {
      type: String
    },
    sessionId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      username: randomName(),
      sessionStatus: "undetermined" as SessionStatus,
      errors: [] as string[],
      displayRolls: [] as DisplayRoll[],
      dices: createNewDiceTable(),
      userId: "",
      message: ""
    };
  },
  created() {
    this.getUserId();
    this.setSessionStatus();
  },
  beforeUnmount() {
    this.$db.ref(`sessions/${this.sessionId}`).off("child_added", this.receiveRoll);
  },
  methods: {
    isCurrentUserRoll(roll: DisplayRoll): boolean {
      return roll.userId === this.userId;
    },
    getUserId() {
      const storedUserId = localStorage.getItem("userId");
      if (storedUserId) {
        this.userId = storedUserId;
      } else {
        this.userId = rs.generate({ length: 16, charset: "alphanumeric" });
        localStorage.setItem("userId", this.userId);
      }
    },
    goBack() {
      if (this.initialUsername) {
        this.$router.push({ name: "home", params: { initialUsername: this.initialUsername } });
      } else {
        this.$router.push({ name: "home" });
      }
    },
    async setSessionStatus() {
      if (this.initialUsername) {
        this.username = this.initialUsername;
      }

      try {
        const session = await this.$db
          .ref(`sessions/${this.sessionId}`)
          .limitToLast(1)
          .once("value");
        if (!session.val()) {
          this.sessionStatus = "doesNotExist";
        } else {
          this.$db
            .ref(`sessions/${this.sessionId}`)
            .limitToLast(ROLLS_TO_DISPLAY)
            .on("child_added", this.receiveRoll);
          this.sessionStatus = "exists";
          localStorage.setItem("lastSessionId", this.sessionId);
        }
      } catch (err) {
        this.errors.push("Server Error.");
      }
    },
    clearDices() {
      this.dices = createNewDiceTable();
    },
    receiveRoll(child: fb.database.DataSnapshot) {
      const val = child.val();
      const roll = strToDices(val.roll);
      this.displayRolls.push({
        user: val.user,
        msg: val.msg,
        roll: roll,
        timestamp: val.timestamp,
        userId: val.userId
      });
      if (this.displayRolls.length > ROLLS_TO_DISPLAY) {
        this.displayRolls.shift();
      }

      const rollWindow = this.$refs.rollWindow as Element;
      if (Math.abs(rollWindow.scrollHeight - rollWindow.scrollTop - rollWindow.clientHeight) <= 2) {
        this.$nextTick(() => (rollWindow.scrollTop = rollWindow.scrollHeight));
      }
    },
    async sendRoll() {
      const rollWindow = this.$refs.rollWindow as Element;
      rollWindow.scrollTop = rollWindow.scrollHeight;

      let msg = "";
      let roll = "";

      if (this.message) {
        msg = this.message;
        this.message = "";
      } else {
        validateDices(this.dices);
        reRollDices(this.dices);
        roll = dicesToStr(this.dices);
      }

      if (!msg && !roll) {
        return;
      }

      await this.$db
        .ref(`sessions/${this.sessionId}`)
        .push()
        .set({ user: this.username, msg: msg, roll: roll, timestamp: timestamp(), userId: this.userId });
    }
  },
  computed: {
    sessionExists(): boolean {
      return this.sessionStatus === "exists";
    },
    sessionDoesNotExist(): boolean {
      return this.sessionStatus === "doesNotExist";
    }
  }
});
